import 'keen-slider/keen-slider.min.css'

import styled from '@emotion/styled'
import { SyncroboxHero } from 'app/components/Common/Header/SyncroboxHero'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { styles } from 'app/theme'
import { Media, MediaContextProvider, mediaStyle } from 'app/theme/media'
import { useKeenSlider } from 'keen-slider/react'
import React, { memo, useState } from 'react'
import { useInView } from 'react-intersection-observer'

interface ImagesProps {
  image?: ImageProps
}

export interface Props {
  images: ImagesProps[]
  label?: string
  languagePrefix?: string
  locality?: string
  title?: string
}

export const HeroSlider = memo(function HeroSlider({
  images,
  label,
  languagePrefix,
  locality,
  title,
}: Props) {
  if (!images) {
    return null
  }
  if (images?.length < 1) {
    return null
  }

  const [animObjectRef, isAnimObjectVisible] = useInView({ triggerOnce: true })
  const [opacities, setOpacities] = useState<number[]>([])

  let timeout: any
  function nextTimeout() {
    clearTimeout(timeout)
    timeout = setTimeout(() => {
      instanceRef.current?.next()
    }, 5000)
  }

  const [sliderRef, instanceRef] = useKeenSlider({
    loop: images.length > 1 ? true : false,
    slides: images.length,
    drag: false,
    detailsChanged(s) {
      nextTimeout()
      const new_opacities = s.track.details.slides.map((slide) => slide.portion)
      setOpacities(new_opacities)
    },
  })

  return (
    <Container>
      <style type="text/css">{mediaStyle}</style>
      <MediaContextProvider>
        <Slider ref={sliderRef} className="keen-slider fader">
          {images.map((item, index) => (
            <Slide
              className="fader__slide"
              key={index}
              style={{ opacity: opacities[index] }}
            >
              <StyledImage {...item.image} />
            </Slide>
          ))}
        </Slider>
        <Wrapper
          ref={animObjectRef}
          className={isAnimObjectVisible ? 'visible' : undefined}
        >
          <Inner>
            <Pretitle>
              Agriturismo Cavazzone
              <Line />
              Viano
            </Pretitle>
            <Title>{title}</Title>
          </Inner>
          <Media greaterThanOrEqual="desktopSmall">
            <SyncroboxHero languagePrefix={languagePrefix || 'IT'} />
          </Media>
        </Wrapper>
      </MediaContextProvider>
    </Container>
  )
})

const Container = styled.section`
  width: 100%;
  height: 100vh;
`

const Slider = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
`

const Slide = styled.div``

const StyledImage = styled(Image)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 24vh;
    background: linear-gradient(180deg, #000000 0%, rgba(84, 84, 84, 0) 70%);
    opacity: 0.45;
    z-index: 1;
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.15);
    z-index: 1;
  }
`

const Wrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 0 8.938vw;
  transform: translateY(-50%);
  opacity: 0;
  transition: opacity 0.6s 0.2s ease-in-out;
  z-index: 2;

  @media (max-width: 1199px) {
    padding: 0 1.25rem;
  }

  &.visible {
    opacity: 1;
    transform: translateY(-50%);
  }
`

const Inner = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  max-width: 31.8rem;
  transform: translate3d(-50%, -50%, 0);
`

const Pretitle = styled.div`
  display: flex;
  white-space: nowrap;
  align-items: center;
  color: ${({ theme }) => theme.colors.variants.primaryLight};
  font-family: ${({ theme }) => theme.fontFamily.label};
  font-size: 1.06rem;
  letter-spacing: 0.1em;
  margin-bottom: 0.8rem;
  text-transform: uppercase;
`

const Title = styled.h1`
  ${styles.h1}
  text-transform: uppercase;
  text-align: center;
  color: ${({ theme }) => theme.colors.variants.primaryLight};
`

const Line = styled.div`
  margin: 0 0.8125rem;
  width: 100%;
  height: 0.0625rem;
  background-color: ${({ theme }) => theme.colors.variants.secondaryDark};

  @media (max-width: 1199px) {
    width: 3.125rem;
  }
`
