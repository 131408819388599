import styled from '@emotion/styled'
import React, { memo } from 'react'

export interface Props {
  children: React.ReactNode
}

export const Background = memo(function Background({ children }: Props) {
  return (
    <Children>
      {children}
      <Container>
        <Wrapper />
      </Container>
    </Children>
  )
})

const Children = styled.div`
  position: relative;
  margin-top: 9.375rem;
  padding: 9.375rem 0;

  @media (max-width: 1199px) {
    margin-top: 5rem;
    padding: 5rem 0;
  }
`

const Container = styled.div`
  position: absolute;
  top: 0;
  right: calc(50% - 41.0625rem);
  height: 100%;
  width: 100vw;
  background-color: ${({ theme }) => theme.colors.variants.neutralLight3};
  z-index: -1;

  @media (max-width: 1600px) {
    right: calc(50% - 41.0625vw);
  }

  @media (max-width: 1199px) {
    right: 2.1875rem;
  }
`

const Wrapper = styled.div`
  width: 100%;
  max-width: 82.125rem;
  margin: 0 auto;
`
