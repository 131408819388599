import styled from '@emotion/styled'
import { Background } from 'app/components/Common/Background'
import { Features } from 'app/components/Features'
import { Heading } from 'app/components/Heading'
import { HeroSlider } from 'app/components/HeroSlider'
import { InstagramSlider } from 'app/components/InstagramSlider'
import { Intro } from 'app/components/Intro'
import { RoomsSlider } from 'app/components/RoomsSlider'
import { SEO } from 'app/components/SEO'
import {
  Props as StructuredDataProps,
  StructuredData,
} from 'app/components/StructuredData'
import { Wide } from 'app/components/Wide'
import { WideMap } from 'app/components/WideMap'
import { Footer, Props as FooterProps } from 'app/containers/Footer'
import { Header, Props as HeaderProps } from 'app/containers/Header'
import { PageProps } from 'gatsby'
import React, { memo } from 'react'
import { LazyLoadComponent } from 'react-lazy-load-image-component'

import { Props } from './contents'

export interface PageContext {
  id: string
  languageCode: string
  languagePrefix: string | null
  headerProps?: HeaderProps
  footerProps?: FooterProps
  structuredDataProps?: StructuredDataProps
}

export interface Context extends PageContext {
  props: Props
}

export default memo(function HomePageTemplate({
  pageContext,
}: PageProps<void, Context>) {
  const context = pageContext as any

  return (
    <Container>
      <SEO {...context.seoProps} />
      {context.structuredDataProps ? (
        <StructuredData
          languageCode={pageContext.languageCode}
          {...context.structuredDataProps}
        />
      ) : null}
      {context.headerProps ? (
        <Header
          languageCode={context.languageCode}
          languagePrefix={context.languagePrefix}
          pageID={context.id}
          {...context.headerProps}
        />
      ) : null}
      {context.heroProps ? (
        <HeroSlider
          {...context.heroProps}
          languagePrefix={context.languagePrefix}
        />
      ) : null}
      {context.introProps ? <Intro {...context.introProps} /> : null}
      <Background>
        {context.headingProps ? <Heading {...context.headingProps} /> : null}
        {context.roomsSliderProps ? (
          <RoomsSlider {...context.roomsSliderProps} />
        ) : null}
      </Background>
      {context.wideProps ? (
        <Wide {...context.wideProps} variant="inverted" />
      ) : null}
      {context.featuresProps ? <Features {...context.featuresProps} /> : null}
      {context.wideBottomProps ? (
        <Wide {...context.wideBottomProps} variant="inverted" />
      ) : null}
      {context.instagramProps ? (
        <InstagramSlider {...context.instagramProps} />
      ) : null}
      {context.footerProps ? (
        <Footer languageCode={context.languageCode} {...context.footerProps} />
      ) : null}
    </Container>
  )
})

const Container = styled.main``
