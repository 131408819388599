import styled from '@emotion/styled'
import { FadeIn } from 'app/components/Common/Animation/FadeIn'
import { Props as ImageProps } from 'app/components/Common/Image'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, { memo } from 'react'

export interface Props {
  className?: string
  image?: ImageProps
  style?: any
  title?: string
  URL?: string
}

export const Item = memo(function Item({ className, style, title }: Props) {
  if (!title) {
    return null
  }

  return (
    <Container row dial={5} className={className} style={style}>
      <FadeIn>
        <Title>{title}</Title>
      </FadeIn>
    </Container>
  )
})

const Title = styled.div`
  margin: 0 4.5vw;
  color: ${({ theme }) => theme.colors.variants.primaryLight};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-weight: normal;
  font-size: 2.6rem;
  line-height: 1;
  text-align: center;
  opacity: 0.6;
  text-transform: uppercase;
  transition: opacity 0.5s ease-in-out;

  @media (max-width: 1199px) {
    font-size: 1.875rem;
    line-height: 2.5rem;
  }

  @media (max-width: 550px) {
    max-width: 12.5rem;
  }
`

const Container = styled(FlexBox)`
  color: ${({ theme }) => theme.colors.variants.primaryLight};

  &.active ${Title} {
    opacity: 1;
  }

  &:before,
  &:after {
    content: '';
    height: 0.05rem;
    background: ${({ theme }) => theme.colors.variants.primaryLight};
    opacity: 1;
    flex: 1;
    min-width: 5rem;

    @media (max-width: 1199px) {
      display: none;
    }
  }
`
