import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/Animation/FadeInUp'
import { Button, Props as ButtonProps } from 'app/components/Common/Button'
import { styles } from 'app/theme'
import React, { memo } from 'react'

export interface Props {
  cta?: ButtonProps
  description?: string
  label?: string
  title?: string
}

export const Heading = memo(function Heading({
  cta,
  description,
  label,
  title,
}: Props) {
  return (
    <Container>
      <Wrapper>
        {label ? (
          <FadeInUp>
            <Label>{label}</Label>
          </FadeInUp>
        ) : null}
        {title ? (
          <FadeInUp>
            <Title>{title}</Title>
          </FadeInUp>
        ) : null}
        <Inner>
          {description ? (
            <FadeInUp>
              <Description dangerouslySetInnerHTML={{ __html: description }} />
            </FadeInUp>
          ) : null}
          {cta ? (
            <FadeInUp>
              <StyledButton {...cta} variant="dark" />
            </FadeInUp>
          ) : null}
        </Inner>
      </Wrapper>
    </Container>
  )
})

const Container = styled.section`
  margin: 0 8.938vw 5rem;

  @media (max-width: 1199px) {
    margin: 0 1.25rem 2.5rem;
    margin-right: 4.6875rem;
  }
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  max-width: 82.125rem;
  margin: 0 auto;

  @media (max-width: 992px) {
    align-items: center;
  }

  @media (max-width: 550px) {
    align-items: flex-start;
  }
`

const Label = styled.div`
  ${styles.label}
`

const Title = styled.h2`
  ${styles.h2}

  @media (max-width: 992px) {
    text-align: center;
  }

  @media (max-width: 550px) {
    text-align: left;
  }
`

const Inner = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 1199px) {
    display: block;
  }
`

const Description = styled.div`
  ${styles.description}
  margin-top :2.5rem;
  margin-right: 8.75rem;

  @media (max-width: 1600px) {
    margin-right: 8.75vw;
  }

  @media (max-width: 1199px) {
    margin-top: 1.875rem;
    margin-right: 0;
  }

  @media (max-width: 992px) {
    text-align: center;
  }

  @media (max-width: 550px) {
    text-align: left;
  }
`

const StyledButton = styled(Button)`
  min-width: max-content;
  margin-right: 6.25rem;

  @media (max-width: 1600px) {
    margin-right: 6.25vw;
  }

  @media (max-width: 1199px) {
    margin-top: 2.5rem;
    margin-right: 0;
  }

  @media (max-width: 992px) {
    margin-left: auto;
    margin-right: auto;
    transform: translateX(2.1875rem);
  }

  @media (max-width: 550px) {
    margin-left: 0;
    margin-right: 0;
    transform: translateX(0);
  }
`
