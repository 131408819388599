import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/Animation/FadeInUp'
import { styles } from 'app/theme'
import React, { memo } from 'react'

interface ItemProps {
  description?: string
  icon?: string
  title: string
}

export interface Props {
  items: ItemProps[]
  label?: string
  title?: string
}

export const Features = memo(function Features({ label, items, title }: Props) {
  if (!items) {
    return null
  }

  return (
    <Container>
      <Wrapper>
        {label ? <Label>{label}</Label> : null}
        {title ? (
          <FadeInUp>
            <Title>{title}</Title>
          </FadeInUp>
        ) : null}
        <FadeInUp>
          <Items>
            {items.map((item, index) => (
              <Item key={index} length={items.length}>
                {item.icon ? (
                  <Icon
                    src={item.icon}
                    alt={item.title}
                    width={40}
                    height={40}
                  />
                ) : null}
                <Name>{item.title}</Name>
                {item.description ? (
                  <Description
                    dangerouslySetInnerHTML={{ __html: item.description }}
                  />
                ) : null}
              </Item>
            ))}
          </Items>
        </FadeInUp>
      </Wrapper>
    </Container>
  )
})

const Container = styled.section`
  margin: 9.375rem 8.938vw 0;

  @media (max-width: 1199px) {
    width: calc(100% - 2.1875rem);
    margin: 5rem 0 0 2.1875rem;
  }
`

const Wrapper = styled.div`
  position: relative;
  max-width: 68.125rem;
  margin: 0 auto;
  padding: 11.875rem 5rem 9.375rem;
  background-color: ${({ theme }) => theme.colors.variants.neutralLight5};
  background-image: url('/wrapper_bg.png');
  background-repeat: no-repeat;
  background-position: center center;

  @media (max-width: 1199px) {
    max-width: 100%;
    padding: 9.875rem 2.5rem 5.375rem;
  }

  @media (max-width: 550px) {
    background-size: 10.875rem 8.25rem;
    background-position: center 3.125rem;
  }
`

const Label = styled.div`
  ${styles.label}
  position: absolute;
  top: 8.125rem;
  left: -6.875rem;

  @media (max-width: 1199px) {
    top: 6.125rem;
    left: -0.9375rem;
  }
`

const Title = styled.h2`
  ${styles.h2}
  max-width: 21.875rem;
`

const Items = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-top: 5rem;
  margin-left: -5rem;
  margin-bottom: -5rem;

  @media (max-width: 1199px) {
    margin-top: 3.75rem;
    margin-left: -3.75rem;
    margin-bottom: -3.75rem;
  }

  @media (max-width: 550px) {
    margin-bottom: -2.75rem;
  }
`

const Item = styled.div<ItemElProps>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: calc(33.33% - 5rem);
  margin-left: 5rem;
  margin-bottom: 5rem;

  ${({ length }) => {
    switch (length) {
      case 5:
        return css`
          width: calc(20% - 1.25rem);
        `
    }
  }}

  @media (max-width: 1199px) {
    width: calc(33.33% - 3.75rem);
    margin-left: 3.75rem;
    margin-bottom: 3.75rem;
  }

  @media (max-width: 992px) {
    width: calc(50% - 3.75rem);
  }

  @media (max-width: 550px) {
    width: 100%;
    margin-bottom: 2.75rem;
  }
`

const Icon = styled.img`
  margin-bottom: 1.25rem;
`

const Name = styled.div`
  color: ${({ theme }) => theme.colors.variants.primaryDark};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-weight: normal;
  font-size: 1.75rem;
  line-height: 1.875rem;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
`

const Description = styled.div`
  margin-top: 0.625rem;
  color: ${({ theme }) => theme.colors.variants.primaryDark};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.6875rem;
`

interface ItemElProps {
  length: number
}
